import GLightbox from "glightbox";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

let swiper = new Swiper();

let lightbox = GLightbox({
	selector: ".video-play",
});

let wrapper = document.querySelector(".testimonials .swiper-wrapper");

document.addEventListener("DOMContentLoaded", function (event) {
	initVideo();
	initTestimonials();
});

function initVideo() {
	lightbox = GLightbox({
		selector: ".video-play",
	});
}

async function initTestimonials() {
	function adjustHeight() {
		setTimeout(function () {
			wrapper.removeAttribute("style");
			swiper.update();
			wrapper.style.height = wrapper.clientHeight + "px";
		}, 100);
	}
	swiper = new Swiper(".testimonials .swiper", {
		modules: [Navigation, Pagination],
		// Optional parameters
		slidesPerView: "auto",
		spaceBetween: 10,
		loop: true,
		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		on: {
			init: function () {
				window.addEventListener("resize", adjustHeight);
				adjustHeight();
			},
		},
	});
}
